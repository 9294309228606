import { JourneyFilterModel, JourneyFilterParams } from '@/lib/types/models/filter';
import { defineStore } from 'pinia';

export const useFiltersStore = defineStore('filters', {
  state: (): { journeysFilterParams: JourneyFilterParams; journeysFilter: JourneyFilterModel } => ({
    journeysFilterParams: {
      month: undefined,
      continents: [],
      themes: [],
      filters: undefined,
      sort: undefined
    },
    journeysFilter: {
      $or: [],
      $and: []
    }
  }),
  actions: {
    setJourneysFilters({ month, continents, themes, filters, sort }: JourneyFilterParams) {
      this.journeysFilterParams = { month, continents, themes, filters };
      this.journeysFilter = { $or: [], $and: [] };

      if (continents && continents.length > 0) {
        this.journeysFilter.$or = [{ continents: { slug: { $contains: continents } } }];
      }

      if (month) {
        this.journeysFilter.$and.push({ months: { slug: { $contains: [month] } } });
      }

      if (themes && themes.length > 0) {
        this.journeysFilter.$and.push({ themes: { slug: { $contains: themes } } });
      }
      if (filters && filters.length > 0) {
        const filterDuration = filters
          .filter(filter => filter.duration)
          .map(filter => ({
            days: { $gte: filter.duration?.min, $lte: filter.duration?.max }
          }));

        const filterPrice = filters
          .filter(filter => filter.price)
          .map(filter => ({
            price: { $gte: filter.price?.min, $lte: filter.price?.max }
          }));

        this.journeysFilter.$and.push({ $or: [...filterDuration] }, { $or: [...filterPrice] });
      }
      if (sort) {
        this.journeysFilterParams.sort = sort;
      }
    },
    clearJourneysFilters() {
      this.journeysFilterParams = {};
      this.journeysFilter = {
        $or: [],
        $and: []
      };
    }
  }
});
